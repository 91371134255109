
/**
 * @name: 订单管理-商城订单
 * @author: itmobai
 * @date: 2023-06-01 09:43
 * @description：订单管理-商城订单
 * @update: 2023-06-01 09:43
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {ICompany, IOrderShopItem, IShopOrder, IShopOrderParam} from "@/apis/order/shop/types";
import {
  orderGetLogisticsInfoApi,
  orderGetLogisticsListApi,
  orderProductOrderExportApi,
  orderSendGoodsApi,
  queryProductOrderByPageApi
} from "@/apis/order/shop";
import {deepCopy, exportFile,getlogisticStatus} from "@/utils/common";

@Component({})
export default class OrderShopPage extends Vue {
  getlogisticStatus = getlogisticStatus
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: IShopOrder[] = []
  // 表单
  modelForm: Partial<IShopOrder> = {}
  // 查询参数
  queryParam: IShopOrderParam = {
    page: 1,
    limit: 10
  }
  // crudOption
  option: ICrudOption = {
    labelWidth: '120px',
    column: [
      {
        "label": "订单编号",
        "prop": "orderSn",
        "align": "left",
        "width": "180",
        "search": true
      },
      {
        "label": "商品",
        "prop": "productName",
        "hide": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true,
        "search": true
      },
      {
        "label": "商品",
        "prop": "productNumber",
        "align": "center",
        "width": "120",
        "slot": true
      },
      {
        "label": "下单金额",
        "prop": "orderPrice",
        "align": "center",
        "width": "150"
      },
      {
        "label": "下单客户",
        "prop": "orderCustom",
        "align": "center",
        "width": "",
        "overHidden": true,
        "search": true
      },
      {
        "label": "订单积分",
        "prop": "integral",
        "align": "center",
        "width": "150"
      },
      {
        "label": "支付方式",
        "prop": "payWay",
        "align": "center",
        "overHidden": false,
        "search": true,
        "type": "select",
        "width": "150",
        "dicData": [
          {
            "label": "余额",
            "value": 1
          },
          {
            "label": "微信支付",
            "value": 2
          }
        ]
      },
      {
        "label": "订单状态",
        "prop": "productOrderStatus",
        "align": "center",
        "width": "150",
        "type": "select",
        "search": true,
        "dicData": [
          {
            "label": "待支付",
            "value": 1,
            "color": "#F56C6C"
          },
          {
            "label": "待发货",
            "value": 2,
            "color": "#F56C6C"
          },
          {
            "label": "待收货",
            "value": 3,
            "color": "#409EFF"
          },
          {
            "label": "已完成",
            "value": 4,
            "color": "#67C23A"
          },
          {
            "label": "已取消",
            "value": 5,
            "color": "#F56C6C"
          },
        ]
      },
      {
        "label": "是否发货",
        "prop": "isSendGoods",
        "align": "center",
        "width": "120",
        "type": "select",
        "dicData": [
          {
            "label": "是",
            "value": 1,
            "color": "#67C23A"
          },
          {
            "label": "否",
            "value": 2,
            "color": "#F56C6C"
          },
        ]
      },
      {
        "label": "运费",
        "prop": "freightPrice",
        "align": "center",
        "width": "120"
      },
      {
        "label": "物流公司",
        "prop": "logisticsCompany",
        "align": "center",
        "width": "150",
        "type": "select",
        "search": true,
        "searchSlot": true,
        "slot": true,
      },
      {
        "label": "物流地址",
        "prop": "deliveryAddress",
        "align": "center",
        "width": "150",
        "overHidden": true,
      },
      {
        "label": "下单时间",
        "prop": "payTime",
        "align": "center",
        "width": "180",
        "type": "daterange",
        "search": true
      },
      {
        "label": "完成时间",
        "prop": "finishTime",
        "search": true,
        "type": "daterange",
        "width": "180",
        "align": "center"
      }
    ]
  }
  // 物流公司大全
  logisticsCompanyList: ICompany[] = []
  // 订单商品弹窗
  shopDialog: boolean = false
  shopList: IOrderShopItem[] = []
  shopSkuData: any = {}
  // 物流信息弹窗
  logisticsDialog: boolean = false;
  logisticsInfo: any = {
    logisticsCompany: '',
    logisticsSn: '',
    list: []
  }

  // 发货弹窗
  deliveryDialog: boolean = false;
  deliveryForm: any = {
    // 快递单号
    logisticsSn: "",
    // 物流公司
    logisticsCompany: "",
    // 物流公司编号
    companyCode: "",
    // 订单id
    id: ""
  }
  deliveryRules: any = {
    companyCode: [
      {required: true, message: '请选择物流公司', trigger: 'blur'}
    ],
    logisticsSn: [
      {required: true, message: '请输入快递单号', trigger: 'blur'}
    ],
  }

  getCompanySelect () {
    orderGetLogisticsListApi().then(e => {
      this.logisticsCompanyList = e;
    })
  }

  /**
   * 查询接口
   */
  getList () {
    this.tableLoading = true
    const query: IShopOrderParam = deepCopy(this.queryParam)
    if (query.payTime && query.payTime.length === 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ""
      query.payTimeEnd = ""
    }
    if (query.finishTime && query.finishTime.length === 2) {
      query.finishTimeStart = query.finishTime[0]
      query.finishTimeEnd = query.finishTime[1]
    } else {
      query.finishTimeStart = ""
      query.finishTimeEnd = ""
    }
    delete query.payTime
    delete query.finishTime
    queryProductOrderByPageApi(query).then(e => {
      this.tableLoading = false
      this.tableData = e.list;
      this.tableTotal = e.total;
    })
  }

  /**
   * 导出excel
   */
  exportExcel () {
    const query: IShopOrderParam = deepCopy(this.queryParam)
    if (query.payTime && query.payTime.length === 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ""
      query.payTimeEnd = ""
    }
    if (query.finishTime && query.finishTime.length === 2) {
      query.finishTimeStart = query.finishTime[0]
      query.finishTimeEnd = query.finishTime[1]
    } else {
      query.finishTimeStart = ""
      query.finishTimeEnd = ""
    }
    delete query.payTime
    delete query.finishTime
    orderProductOrderExportApi(query).then(e => {
      exportFile(e, "商城订单.xlsx")
    })
  }

  /**
   * 打开商品列表
   * @param row
   */
  openOrderShopDialog (row: IShopOrder) {
    this.shopList = row.productOrderDtlVoList;
    const skuObj: any = {}
    if (row.productOrderDtlVoList && row.productOrderDtlVoList.length) {
      for (let i = 0; i< row.productOrderDtlVoList.length; i++) {
        let spData = row.productOrderDtlVoList[i].spData;
        spData = spData.replaceAll('\\"', '"')
        if (spData) {
          spData = JSON.parse(spData)
          for (let j = 0; j < spData.length; j++) {
            const spDataItem: any = spData[j]
            const key = spDataItem['key']
            const value = spDataItem['value']
            if (!skuObj[key]) {
              skuObj[key] = {
                data: []
              }
            }
            skuObj[key]['data'].push(value)
          }
        }
      }
    }
    this.shopSkuData = skuObj;
    this.shopDialog = true
  }

  /**
   * 发货
   */
  handleSend () {
    // @ts-ignore
    this.$refs.deliveryFormRef.validate(valid => {
      if (valid) {
        orderSendGoodsApi(this.deliveryForm).then(e => {
          if (e) {
            this.deliveryDialog = false;
            this.$message.success("操作成功!")
            this.getList();
            this.deliveryForm = {
              // 快递单号
              logisticsSn: "",
              // 物流公司
              logisticsCompany: "",
              // 物流公司编号
              companyCode: "",
              // 订单id
              id: ""
            }
          }
        })
      }
    })
  }

  openDelivery (id: string) {
    this.deliveryForm.id = id;
    this.deliveryDialog = true
  }

  handleCompanyCodeChange (value: string) {
    if (!value) {
      return ""
    }
    const item = this.logisticsCompanyList.find(item => item.companyCode === value)
    if (item && item.companyName) {
      this.deliveryForm.logisticsCompany = item.companyName;
    }
  }

  openLogistics (row: IShopOrder) {
    this.logisticsInfo = {
      logisticsCompany: row.logisticsCompany,
      logisticsSn: row.logisticsSn,
    }
    orderGetLogisticsInfoApi(row.id).then(e => {
      this.logisticsInfo.list = e;
      this.logisticsDialog = true
    })
  }

  created () {
    this.getCompanySelect()
    this.getList()
  }
}
