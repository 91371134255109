/**
 * @name: 订单管理-商城订单接口
 * @author: itmobai
 * @date: 2023-06-01 09:59
 * @description：订单管理-商城订单接口
 * @update: 2023-06-01 09:59
 */
import {get,postJ} from "@/request";
import {IPageRes} from "@/apis/page";
import {ICompany, IShopOrder, IShopOrderParam} from "@/apis/order/shop/types";

/**
 * 商城订单分页查询
 * @param param 查询参数
 */
export const queryProductOrderByPageApi = (param: IShopOrderParam) => get<IPageRes<IShopOrder[]>>("/golf/order/queryProductOrderByPage", param)
/**
 * 物流公司查询
 */
export const orderGetLogisticsListApi = () => get<ICompany[]>("/golf/order/getLogisticsList")
/**
 * 商城订单导出
 * @param param 查询参数
 */
export const orderProductOrderExportApi = (param: IShopOrderParam) => get("/golf/order/productOrderExport", param, "blob")
/**
 * 订单发货
 * @param data
 */
export const orderSendGoodsApi = (data: any) => postJ("/golf/order/sendGoods", data)
/**
 * 获取物流信息
 * @param orderId 订单id
 */
export const orderGetLogisticsInfoApi = (orderId: string) => get("/golf/order/getLogisticsInfo", {orderId})
